import {
  IBannersData,
  IBannerViewModel,
} from "@/fe-core/meta/interfaces/banner";
import { BannerState } from "@/fe-core/meta/types/banner";
import { RootState } from "@/fe-core/meta/types/root";

export const cmsBannersSelector = ({ banners }: RootState): BannerState =>
  banners;

export const bannersSelector = (state: RootState): IBannersData | null => {
  const { banners } = cmsBannersSelector(state);
  return banners;
};
export const bannersPendingSelector = (state: RootState): boolean => {
  const { pending } = cmsBannersSelector(state);
  return pending;
};

export const getBannersByPageSelector =
  (state: RootState): ((page: string) => IBannerViewModel[] | null) =>
  (page: string): IBannerViewModel[] | null => {
    const banners = bannersSelector(state);
    if (!banners) {
      return null;
    }

    return banners[page];
  };
