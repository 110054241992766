"use client";
import Image, { StaticImageData } from "next/image";
import React, { FC } from "react";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../SVGIcon";
import Button from "../Button";
import { Link } from "../../navigation";
import Diamond from "/public/img/diamond.svg";
import { useSelector } from "react-redux";
import { paymentsSelector } from "@/fe-core/_redux/selectors/footerSelectors";

type Props = {
  className?: string;
};

const Categories: FC<Props> = ({ className = "" }) => {
  const t = useTranslations(I18N_NAMESPACE.CATEGORIES);

  return (
    <>

      <div
        className={`container relative flex flex-wrap overflow-auto lg:whitespace-normal whitespace-nowrap  gap-4 md:gap-10 justify-center ${className}`}
      >
        <LinkCard
          href="/casino"
          imgSrc={Diamond}
          altText={t("slots")}
          icon="casino"
          header={t("slots-header")}
        />
        <LinkCard
          href="/sports"
          imgSrc={Diamond}
          altText={t("sports")}
          icon="sports"
          header={t("sports-header")}
        />
        <LinkCard
          href="/casino/table"
          imgSrc={Diamond}
          altText={t("live")}
          icon="dealer"
          header={t("live-header")}
        />
        {/* <LinkCard
        href="/promotions"
        imgSrc={Diamond}
        altText={t("promotions")}
        icon="casual"
        header={"Casual Games"}
      /> */}
        <LinkCard
          href="/promotions"
          imgSrc={Diamond}
          altText={t("promotions")}
          icon="promotion"
          header={t("promotions-header")}
        />
      </div>
    </>
  );
};

type LinkCardProps = {
  href: string;
  imgSrc: StaticImageData;
  altText: string;
  icon: string;
  header: string;
};

const LinkCard: FC<LinkCardProps> = ({
  href,
  imgSrc,
  altText,
  icon,
  header,
}) => (
  <Link
    href={href}
    className="flex flex-col overflow-hidden transition-all cursor-pointer max-w-[90px] lg:max-w-[140px]  group w-full "
  >
    <div className="text-center aspect-[140/106] relative flex items-center justify-center pb-2 lg:pb-6 ">
      <SVGIcon
        icon={icon}
        className={
          "w-7 h-7 lg:w-12 lg:h-12 text-primary-500 dark:text-primary-500 relative z-[1] group-hover:text-primary-500 group-hover:scale-110 transform transition-all"
        }
      />
      <Image
        src={imgSrc}
        alt={altText}
        layout="fill"
        objectFit="contain"
        objectPosition="center"
        loading="lazy"
      />
    </div>
    <div className="relative top-0 flex flex-col justify-between flex-1 px-0 transition-all text-primary-500 ">
      <div>
        <div className="flex items-center gap-2 justify-center mt-2 md:mt-3  ">
          <h4 className="font-normal text-xs md:text-base lg:text-xl whitespace-nowrap">
            {header}
          </h4>
        </div>
      </div>
    </div>
  </Link>
);

export default Categories;
