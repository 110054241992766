"use client";
import { FC, useMemo, useState } from "react";
import Image, { StaticImageData } from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  IBannersViewModel,
  IBannerViewModel,
  ICTAModel,
  ISlideModel,
} from "@/fe-core/meta/interfaces/banner";
import { SLIDER_THEME, SLIDE_TYPE, BannerPages } from "@/config/cms";
import { useScreen } from "../context/ScreenContext";
import { SCREEN_LEVELS } from "@/config/general";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  bannersPendingSelector,
  getBannersByPageSelector,
} from "@/fe-core/_redux/selectors/bannerSelectors";
// import strikeImg from "/public/images/strike-strip.svg";
import { motion, Variants } from "framer-motion";
import Button from "./Button";
import {
  isUserAuthenticatedSelector,
  sessionAuthenticatePendingSelector,
} from "@/fe-core/_redux/selectors/sessionSelectors";
import {
  closeLoginMenu,
  openLoginMenu,
} from "@/fe-core/_redux/actions/menusActions";
import { setShowSignup } from "@/fe-core/_redux/actions/userActions";
import {
  userRolesDataSelector,
  userRolesStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import {
  userBonusesActiveSelector,
  userGetBonusesStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors/bonusSelectors";
import useTracking from "./Hooks/useTracking";
import { Link } from "../navigation";
import { filterCmsData } from "@/fe-core/helpers/utils";
import { Logo } from "./Logo";
import { paymentsSelector } from "@/fe-core/_redux/selectors/footerSelectors";

type SlideProps = {
  title?: string;
  style: string;
  image: StaticImageData | string;
  text1?: string;
  text2?: string;
  buttonText?: string;
  url?: string;
  placeholder?: string;
  index: number;
  slide: ISlideModel;
  isAuthenticated: boolean;
  isIdentified: boolean;
};

const getuserStateCta = (
  cta: ICTAModel,
  isAuthenticated: boolean,
  isIdentified: boolean
) => {
  if (!!+isAuthenticated) {
    return { label: cta?.labelAuthenticated, link: cta?.linkAuthenticated };
  } else if (!!+isIdentified) {
    return { label: cta?.labelIdentified, link: cta?.linkIdentified };
  } else {
    return { label: cta?.labelPublic, link: cta?.linkPublic };
  }
};
const Slide: FC<SlideProps> = ({
  image,
  title,
  index,
  text1,
  text2,
  buttonText,
  url,
  style,
  placeholder,
  slide,
  isAuthenticated,
  isIdentified,
}) => {
  const router = useRouter();
  const { click } = useTracking();
  const dispatch = useDispatch();
  const paymentsData = useSelector(paymentsSelector);

  const handleCtaClick = (url: string) => {
    switch (url) {
      case "/register":
        dispatch(closeLoginMenu());
        dispatch(setShowSignup());
        click(`Banner`, {
          isRegModal: "yes",
          component: "MainSlider - Slide",
        });
        break;
      case "/login":
        dispatch(openLoginMenu());
        click(`Banner`, {
          isLoginModal: "yes",
          component: "MainSlider - Slide",
        });
        break;
      default:
        click(`Banner`, {
          url: url,
          component: "MainSlider - Slide",
        });
        router.push(url);
        break;
    }
  };

  // image only
  if (style === SLIDE_TYPE.CTA_SLIDE) {
    const cta = getuserStateCta(slide.cta, isAuthenticated, isIdentified);
    const HeadingTag = index === 0 ? "h1" : "h2";
    return (
      <div className="container ">
        <div className="relative z-[1]  ">
          <div className="lg:relative z-10 max-w-2xl gap-5 px-0 right-0 space-y-3 lg:space-y-5 text-center ">
            <div className="lg:block hidden">
              <Logo logoClassName={`sm:!h-[64px]`} />
            </div>
            <h4 className="text-xs sm:text-sm font-bold uppercase  md:text-2xl text-white lg:text-primary-500 lg:block hidden text-center">
              {slide?.tagline?.split("|")[0]}
              {slide?.tagline?.split("|")[1] && (
                <span className="relative">
                  {slide?.tagline?.split("|")[1]}
                </span>
              )}
            </h4>
            <HeadingTag className="md:text-2xl text-2xl lg:text-5xl font-bodoni text-white  !leading-normal  drop-shadow-md">
              {slide?.headline?.split("|")[0]}
              {slide?.headline?.split("|")[1] && (
                <span className="lg:text-[90px] text-[64px] block">
                  {slide?.headline?.split("|")[1]}
                </span>
              )}
            </HeadingTag>
            <Button
              className="lg:btn--lg inline-flex mx-auto"
              type="primary"
              label={cta?.label}
              onClick={() => handleCtaClick(cta?.link)}
            />
            {paymentsData && paymentsData?.length > 0 && (
              <div className="flex  flex-wrap justify-center items-center gap-4 lg:gap-y-3 lg:gap-x-6 mt-8 max-w-60 mx-auto">
                {paymentsData?.map((item, index) => {
                  return (
                    <div
                      key={index + "__payment_item"}
                      className={`w-10 pointer-events-none`}
                    >
                      {item?.icons?.data?.attributes?.url && (
                        <img
                          src={`${process.env.NEXT_PUBLIC_CMS_URL}${item?.icons?.data?.attributes?.url}`}
                          className="!relative mx-auto max-h-6 filter grayscale opacity-50"
                          alt={
                            item.icons?.data?.attributes?.alternativeText ||
                            item?.name
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {image && (
          <Image
            src={`${process.env.NEXT_PUBLIC_CMS_URL}${image}`}
            alt={title || "img"}
            className="top-0 overflow-hidden object-top md:object-center"
            // placeholder="blur"
            objectFit="cover"
            fill
            // blurDataURL={placeholder}
            priority={true}
          />
        )}

        <div className="bg-gradient-to-t from-tertiary-600 w-full via-tertiary-600/70 to-transparent absolute bottom-0 left-0 h-20" />
      </div>
    );
  }

  // text and action button
  if (style === SLIDE_TYPE.ACTION_SLIDE) {
    return (
      <div className="slide main-slide action-slide">
        <Image
          src={image}
          alt={title || "img"}
          fill
          sizes="100vw"
          style={{
            objectFit: "cover",
          }}
          loading="lazy"
        />
        <div className="info">
          {text1 && <h2>{text1}</h2>}
          {text2 && <h3>{text2}</h3>}
          {url && (
            <Link href={url}>
              <button className="btn-slider accent">
                {buttonText ? buttonText : "Mai mult"}
              </button>
            </Link>
          )}
        </div>
      </div>
    );
  }

  // text and action button
  if (style === SLIDE_TYPE.LINK_SLIDE) {
    return (
      <div className="slide main-slide link-slide">
        {url && (
          <Link href={url}>
            <Image
              src={image}
              alt={title || "img"}
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
              }}
              loading="lazy"
            />
          </Link>
        )}
      </div>
    );
  }

  // image without content
  if (style === SLIDE_TYPE.EVENT_SLIDE) {
    return (
      <div className="slide main-slide event-slide">
        <div className="slide-image">
          <Image
            src={image}
            alt={title || "img"}
            fill
            sizes="100vw"
            style={{
              objectFit: "cover",
            }}
            loading="lazy"
          />
        </div>
        <div className="slide-info">
          <div className="left-info">
            <span>Benfica</span>
            <span>Ajax</span>
          </div>
          <div className="right-info">
            <span>Miercuri, 23 Feb</span>
            <span>22:00</span>
          </div>
        </div>
        <div className="slide-actions">
          <div className="option">
            <span className="label">1</span>
            <span className="value">2.33</span>
          </div>
          <div className="option">
            <span className="label">X</span>
            <span className="value">3.30</span>
          </div>
          <div className="option">
            <span className="label">2</span>
            <span className="value">3.75</span>
          </div>
        </div>
      </div>
    );
  }

  // footer jackpot
  // if (style === SLIDE_TYPE.JACKPOT_SLIDE) {
  //   return (
  //     <div className="slide jackpot-slide">
  //       <Image src={image} height={75} alt={title} />
  //       <div className="title">{title}</div>
  //       <div className="jackpot">$235.98</div>
  //     </div>
  //   );
  // }

  return <></>;
};

type SliderProps = {
  theme: string;
  mainBanner: IBannersViewModel | null;
};

const MainSlider: FC<SliderProps> = ({ theme, mainBanner }) => {
  const { click } = useTracking();
  const pathname = usePathname() ?? "";
  const dispatch = useDispatch();
  const router = useRouter();
  const isIdentified = false;
  const isBannerPending = useSelector(bannersPendingSelector);
  const sessionAuthenticatedPending = useSelector(
    sessionAuthenticatePendingSelector
  );
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const userRoles = useSelector(userRolesDataSelector);
  const userRolesStatus = useSelector(userRolesStatusSelector);
  const bonusesActiveStatus = useSelector(userGetBonusesStatusSelector);
  const bonusesActive = useSelector(userBonusesActiveSelector);

  const isLive = pathname.includes("/casino/live");

  const banners = useMemo(() => {
    const positionBanner = isLive
      ? mainBanner?.data?.[BannerPages.CASINO_LIVE_PAGE]
      : mainBanner?.data?.[BannerPages.CASINO_PAGE];
    if (typeof window !== "undefined") {
      return filterCmsData(positionBanner as IBannerViewModel[]);
    } else {
      return positionBanner;
    }
  }, [mainBanner, userRoles, isAuthenticated, bonusesActive, isLive]);

  const { screenSizeLevel } = useScreen();
  const [clientXonMouseDown, setClientXonMouseDown] = useState<null | number>(
    null
  );
  const [clientYonMouseDown, setClientYonMouseDown] = useState<null | number>(
    null
  );

  const handleOnMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault();
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>, url: string) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      if (url) {
        click(`Main Banner`, {
          url: url,
          component: "MainSlider",
        });
        router.push(url);
      }
    }
  };

  const mainCarouselSliders = () => {
    if (banners) {
      return banners?.map((item, index) => {
        //let style = SLIDE_TYPE.LINK_SLIDE;
        let style = item.attributes.slide.sliderType;
        // if (item.attributes.fixture?.data) {
        //   style = SLIDE_TYPE.EVENT_SLIDE;
        // }
        let imgURL = "";
        let imgTitle = "default";

        switch (screenSizeLevel) {
          case SCREEN_LEVELS.MOBILE:
          case SCREEN_LEVELS.TABLET:
            if (item?.attributes?.slide?.mobileImage?.data !== null) {
              imgURL =
                item?.attributes?.slide?.mobileImage?.data?.attributes?.url;
              imgTitle =
                item?.attributes?.slide?.mobileImage?.data?.attributes?.name;
            }
            break;
          case SCREEN_LEVELS.DESKTOP:
          case SCREEN_LEVELS.DESKTOP_LARGE:
            if (item?.attributes?.slide?.desktopImage?.data !== null) {
              imgURL =
                item?.attributes?.slide?.desktopImage?.data?.attributes?.url;
              imgTitle =
                item?.attributes?.slide?.desktopImage?.data?.attributes?.name;
            }
            break;
          default:
            imgURL = "";
            imgTitle = "default";
            break;
        }

        return (
          <SwiperSlide key={`SwiperSlide__${index}__${item?._id}`}>
            <div
              className="flex items-end lg:items-center flex-1 h-full pb-0 lg:pb-44  "
              onClick={(event) => handleOnClick(event, item.attributes.url)}
              onMouseDown={(event) => handleOnMouseDown(event)}
            >
              <Slide
                index={index}
                image={`${imgURL}`}
                isAuthenticated={isAuthenticated}
                isIdentified={isIdentified}
                // title={`${imgTitle}`}
                // text1={item.attributes?.Text1}
                // text2={item.attributes?.Text2}
                // buttonText={item.attributes?.buttonText}
                // url={item.attributes?.url}
                slide={item.attributes.slide}
                // placeholder={
                //   item.attributes.desktopImage.data.attributes.placeholder
                // }
                style={style}
              />
            </div>
          </SwiperSlide>
        );
      });
    }
    return <></>;
  };

  if (theme === SLIDER_THEME.MAIN && banners && banners?.length > 0) {
    return (
      <div className="flex relative min-h-full">
        <Swiper
          spaceBetween={24}
          slidesPerView={1}
          // slidesPerView={1}
          // centeredSlides={banners?.length > 1 ? true : false}
          loop={banners?.length > 1 || false}
          pagination={banners?.length > 1 || false}
          navigation={false}
          simulateTouch={true}
          draggable={true}
          className="!h-auto w-full"
        >
          {banners && mainCarouselSliders()}
        </Swiper>
      </div>
    );
  }

  // if (!banners && isBannerPending) {
  //   return (
  //     <div className="container">
  //       <div className="  ">
  //         <Swiper
  //           spaceBetween={24}
  //           slidesPerView={1}
  //           // slidesPerView={1}
  //           centeredSlides={true}
  //           loop={true}
  //           pagination={false}
  //           navigation={false}
  //         >
  //           <SwiperSlide>
  //             <Skeleton
  //               // count={3}
  //               containerClassName="skeleton-banner h-[294px] w-full !flex overflow-hidden rounded "
  //               // className="2xl:min-w-[1194px] min-w-[100vw] !w-auto flex flex-grow flex-1 align-top mx-2 transform -translate-x-full relative aspect-slider"
  //             />
  //           </SwiperSlide>
  //         </Swiper>
  //       </div>
  //     </div>
  //   );
  // }

  return <></>;
};

export default MainSlider;
