import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/BetByHandlers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/CasinoPromotionsSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/Categories/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/InitWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/Layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/Layout/NavMenu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/opt/atlassian/pipelines/agent/build/components/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/MainSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/HeaderComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/HeaderCTA.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/LayoutChatbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/LayoutDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/LayoutFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/LayoutHeaderNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/MenuButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/NewsBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/NavPage/PostMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/ReduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScreenProvider"] */ "/opt/atlassian/pipelines/agent/build/context/ScreenContext.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/script.js");
